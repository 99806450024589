import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { format } from "date-fns"

import Button from "./Button"

const Newsletter = () => {
  const data = useStaticQuery(graphql`
    query NewsletterFile {
      allContentfulNewsletter {
        edges {
          node {
            lastUpdated
            newsletterFile {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { lastUpdated, newsletterFile } = data.allContentfulNewsletter.edges[0].node
  const newsletterUrl = newsletterFile.file.url

  const dateObj = new Date(lastUpdated.replace(
    /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/, '$4:$5:$6 $2/$3/$1'
  ))
  const formattedDate = format(dateObj, "PPP")

  return (
    <section className="grid__item grid__item--center section" id="newsletter">
      <div className="newsletter">
        <h2 className="heading newsletter__heading">NEWSLETTER</h2>
        <h3 className="newsletter__date">Last Updated: {formattedDate}</h3>
        <Button color="white" linkUrl={newsletterUrl}>
          View Newsletter
        </Button>
      </div>
    </section>
  )
}

export default Newsletter
