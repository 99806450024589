import { useState } from "react";

export default function useToggle(initialVal) {
  const [state, setState] = useState(initialVal);

  function toggle() {
    setState(!state);
  };
  
  return [state, toggle];
}