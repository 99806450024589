import React from "react"

import Hero from "../components/Hero"
import About from "../components/About"
import Divider from "../components/Divider"
import Gallery from "../components/Gallery"
import Newsletter from "../components/Newsletter"
import { Helmet } from "react-helmet"

const IndexPage = () => (
  <>
    <Helmet>
      <title>Fraser Community Child Care Centre & Out of School Care</title>
      <link rel="canonical" href="https://frasercommunitychildcarecenter.com" />
    </Helmet>
    <Hero />
    <div className="grid">
      <About />
      <Divider />
      <Gallery />
      <Newsletter />
    </div>
  </>
)

export default IndexPage
