import React from "react"

import Button from "./Button"

import aboutImage from "../images/about-image.png"

const About = () => (
  <section className="section grid__item--wide about" id="about">
    <div className="about__text">
      <h2 className="heading heading--section">About Us</h2>
      <p className="body-text">Our mission is to provide quality care in a Learning Through Play environment where parents and staff work together. We want each and every child to grow emotionally, socially, intellectually and physically; all of our staff to feel valued as a part of our team; and every parent to know that we think that they and their kids are special.</p>
      <Button color="green" linkUrl="/about" spaceAbove>Learn More</Button>
    </div>
    <div className="about__image">
      <img src={aboutImage} alt="About" />
    </div>
  </section>
)

export default About
