import React from "react"

import HeroImage from "../images/hero.jpg"

const Hero = () => (
  <section className="hero" id="hero">
    <img src={HeroImage} alt="Hero" />
  </section>
)

export default Hero
