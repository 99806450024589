/* eslint-disable */
import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Lightbox from "react-images"

import useToggle from "../hooks/useToggle"

const Gallery = () => {
  const [showingLightbox, toggleShowingLightbox] = useToggle(false)
  const [photoIdx, setPhotoIdx] = useState(0)

  const data = useStaticQuery(graphql`
    query {
      allContentfulImageGallery {
        edges {
          node {
            images {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `)

  const imageFluids = data.allContentfulImageGallery.edges[0].node.images.map(
    image => image.fluid
  )
  const images = imageFluids.map(fluid => ({ src: fluid.src }))
  

  function gotoPrevLightboxImage() {
    setPhotoIdx(photoIdx - 1)
  }

  function gotoNextLightboxImage() {
    setPhotoIdx(photoIdx + 1)
  }

  function openLightbox(photoIdx) {
    setPhotoIdx(photoIdx)
    toggleShowingLightbox()
  }

  return (
    <section className="section grid__item grid__item--center" id="gallery">
      <h2 className="heading heading--section">Gallery</h2>
      <div className="gallery__grid">
        {imageFluids.map((fluid, i) => (
          <div
            className="gallery__image-container"
            onClick={() => openLightbox(i)}
            key={i}
          >
            <img
              className="gallery__image"
              alt="Gallery Image"
              src={fluid.src}
            />
          </div>
        ))}
      </div>

      <Lightbox
        backdropClosesModal
        images={images}
        currentImage={photoIdx}
        isOpen={showingLightbox}
        onClickPrev={gotoPrevLightboxImage}
        onClickNext={gotoNextLightboxImage}
        onClose={toggleShowingLightbox}
      />
    </section>
  )
}

export default Gallery
